import { calcoloCosto } from "../FUN_CALCOLI";

// Funzione generica per creare il riepilogo
const handleCreateTabACQ_RESUME = async (
  ACQ_SEL,
  fileData,
  setQuantitaTOT,
  setSpesaTOT,
  setAcqSelGareList,
  criterioConfronto
) => {
  // Filtra gli elementi in base al criterio
  const ARRAY_TEMP = [];
  let Q_TOT = 0;
  let SPESA_TOT = 0;

  fileData.forEach((SEL) => {
    if (criterioConfronto(ACQ_SEL, SEL)) {
      Q_TOT += SEL.quantita;
      SPESA_TOT += calcoloCosto(SEL.prezzo_unitario, SEL.quantita);
      ARRAY_TEMP.push(SEL);
    }
  });

  // Aggiorna la lista delle gare con quantità e spese totali
  ACQ_SEL?.garePubList?.forEach((SEL) => {
    SEL.quantita_totale = Q_TOT;
    SEL.spesa_totale = Math.round(SPESA_TOT * 100) / 100;
    SEL.spesa_temp = calcoloCosto(SEL.prezzo_unitario, Q_TOT);
  });

  // Aggiorna gli stati
  setQuantitaTOT(Q_TOT);
  setSpesaTOT(Math.round(SPESA_TOT * 100) / 100);
  setAcqSelGareList([...ACQ_SEL.garePubList]);

  return ARRAY_TEMP;
};

// Gestione del dettaglio singolo
const handleSingleDetail = async (
  params,
  fileData,
  setQuantitaTOT,
  setSpesaTOT,
  setAcqSelGareList,
  setGareSingleDetail
) => {
  const ACQ_TAB_TEMP = await handleCreateTabACQ_RESUME(
    params.row,
    fileData,
    setQuantitaTOT,
    setSpesaTOT,
    setAcqSelGareList,
    (ACQ_SEL, SEL) =>
      ACQ_SEL.descrizione_gruppo_ospedaliero ===
      SEL.descrizione_gruppo_ospedaliero
  );

  setGareSingleDetail(ACQ_TAB_TEMP);
};

// Gestione del dettaglio singolo raggruppato
const handleSingleDetailGrouped = async (
  params,
  fileData,
  setQuantitaTOT,
  setSpesaTOT,
  setAcqSelGareList,
  setGareSingleDetail
) => {
  const ACQ_TAB_TEMP = await handleCreateTabACQ_RESUME(
    params.row,
    fileData,
    setQuantitaTOT,
    setSpesaTOT,
    setAcqSelGareList,
    (ACQ_SEL, SEL) => ACQ_SEL.tipologia === SEL.descrizione_gruppo_ospedaliero
  );

  setGareSingleDetail(ACQ_TAB_TEMP);
};

export { handleSingleDetail, handleSingleDetailGrouped };
