import React from "react";
import "../../../App.css";

// COLUMNS
import { OFF_MAIN_COLUMNS } from "../../../colums/OFF_ANALYSIS";

// MUI ELEMENTS
import Button from "@mui/material/Button";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// ICONS
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";

// LOADERS
import BeatLoader from "react-spinners/BeatLoader";

const OffersAnalysisDetails = ({
  handleBackToOfferSEL,
  analisiTipologiaFlag,
  loadingOffersGroupList,
  offersResumeList,
  offersResumeSelected,
  setOffersResumeSelected,
  loadingPurchasedList,
  purchasedList,
  setPurchasedSelected,
  PurchasedSelected,
  handleAnalisiTipologia,
  handleViewDetailsTab,
  loadingAnalisiTipologia,
  finalAnalysisOBJ,
}) => {
  return (
    <>
      <div className="off-an-container">
        <div className="off-an-head-left">
          <Button
            variant="contained"
            color="redtest"
            startIcon={<ChevronLeftOutlinedIcon />}
            onClick={handleBackToOfferSEL}
          >
            INDIETRO
          </Button>
        </div>
        <div className="off-an-head-center">
          <h1 className="off-an-title">ANALISI OFFERTE</h1>
        </div>
        <div className="off-an-head-right"></div>
      </div>
      {!analisiTipologiaFlag ? (
        <>
          <div>
            {loadingPurchasedList ? (
              <div style={{ textAlign: "center", paddingTop: "2%" }}>
                <BeatLoader
                  color={"#1976d2"}
                  loading={loadingPurchasedList}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                  CARICAMENTO...
                </h6>
              </div>
            ) : (
              <>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={purchasedList}
                  getOptionLabel={(option) => option.nome_salvataggio}
                  sx={{
                    width: "100%",
                    marginTop: "3%",
                    padding: "0 35%",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="ACQUISTATI" />
                  )}
                  value={PurchasedSelected}
                  onChange={(e, newValue) => {
                    setPurchasedSelected(newValue);
                  }}
                />
              </>
            )}

            {loadingOffersGroupList ? (
              <div style={{ textAlign: "center", paddingTop: "2%" }}>
                <BeatLoader
                  color={"#1976d2"}
                  loading={loadingOffersGroupList}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                  CARICAMENTO...
                </h6>
              </div>
            ) : (
              <>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={offersResumeList}
                  getOptionLabel={(option) => option.nome_listino}
                  sx={{
                    width: "100%",
                    marginTop: "3%",
                    padding: "0 35%",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="LISTINI" />
                  )}
                  value={offersResumeSelected}
                  onChange={(e, newValue) => {
                    setOffersResumeSelected(newValue);
                  }}
                />
              </>
            )}

            {PurchasedSelected !== null && offersResumeSelected !== null && (
              <>
                <div
                  style={{
                    paddingTop: "2%",
                    textAlign: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="bluetest"
                    endIcon={<QueryStatsOutlinedIcon />}
                    onClick={handleAnalisiTipologia}
                  >
                    ESEGUI VALUTAZIONE
                  </Button>
                </div>
                {loadingAnalisiTipologia && (
                  <div style={{ textAlign: "center", paddingTop: "2%" }}>
                    <BeatLoader
                      color={"#1976d2"}
                      loading={loadingAnalisiTipologia}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                    <h6
                      style={{
                        fontFamily: "Calibri",
                        color: "#1976d2",
                      }}
                    >
                      CARICAMENTO...
                    </h6>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <Box
            sx={{
              height: "417px",
              width: "100%",
              padding: "0 1%",
              marginTop: "1.5%",
              "& .theme_header": {
                backgroundColor: "#1976d2",
                color: "#fff",
                fontFamily: "Calibri",
                fontSize: "16px",
              },
              "& .theme_cell": {
                fontFamily: "Calibri",
                fontSize: "17px",
                border: "1px solid",
              },
            }}
          >
            <h3
              style={{
                marginBottom: "0.5%",
                textAlign: "center",
                color: "#1976d2",
                fontFamily: "Calibri",
              }}
            >
              DOPPIO CLICK PER IL DETTAGLIO
            </h3>
            <DataGridPro
              columns={OFF_MAIN_COLUMNS}
              rows={finalAnalysisOBJ}
              getRowId={(row) => row.id_temp}
              headerHeight={40}
              rowHeight={33}
              onRowDoubleClick={handleViewDetailsTab}
              disableColumnMenu
              hideFooter
            />
          </Box>
        </>
      )}
    </>
  );
};

export default OffersAnalysisDetails;
