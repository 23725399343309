import React from "react";
import "../../../App.css";

// EXPS
import EXP_ANALYSIS_OFF_COMPLETE from "../../../exports/OFF/EXP_ANALYSIS_OFF_COMPLETE";

// MUI ELEMENTS
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// ICONS
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";

// LOADERS
import BeatLoader from "react-spinners/BeatLoader";

const OffersAnalysisComplete = ({
  handleBackToOfferSEL,
  handleSelectSingleOffer,
  handleSelectOffersResume,
  handleValutazioneOFF,
  singleOfferSelected,
  offersListSuppliers,
  loadingOffersResumeList,
  loadingMultipleOfferAnalysis,
  offersResumeList,
  offersResumeSelected,
  VAL_Flag,
  supplierNOTFoundFlag,
  completezzaOFF_OBJ,
}) => {
  return (
    <>
      <div className="off-an-container">
        <div className="off-an-head-left">
          <Button
            variant="contained"
            color="redtest"
            startIcon={<ChevronLeftOutlinedIcon />}
            onClick={handleBackToOfferSEL}
          >
            INDIETRO
          </Button>
        </div>
        <div className="off-an-head-center">
          <h1 className="off-an-title">COMPLETEZZA OFFERTE</h1>
        </div>
        <div className="off-an-head-right"></div>
      </div>

      {singleOfferSelected === null ? (
        <div>
          <Autocomplete
            disablePortal
            id="single-offer-select"
            options={offersListSuppliers}
            getOptionLabel={(option) => option.ragione_sociale}
            sx={{
              width: "100%",
              marginTop: "3%",
              padding: "0 35%",
            }}
            renderInput={(params) => (
              <TextField {...params} label="OFFERTE SINGOLE" />
            )}
            onChange={(e, newValue) => handleSelectSingleOffer(newValue)}
          />
        </div>
      ) : (
        <div>
          {loadingOffersResumeList ? (
            <div className="off-an-loading">
              <BeatLoader
                color="#1976d2"
                loading={loadingOffersResumeList}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                CARICAMENTO...
              </h6>
            </div>
          ) : (
            <>
              <Autocomplete
                disablePortal
                id="list-select"
                options={offersResumeList}
                getOptionLabel={(option) => option.nome_listino}
                sx={{
                  width: "100%",
                  marginTop: "5%",
                  padding: "0 35%",
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Listini" />
                )}
                value={offersResumeSelected}
                onChange={(e, newValue) => handleSelectOffersResume(newValue)}
              />
              <h3 className="off-an-text">
                SELEZIONA IL LISTINO SUL QUALE VALUTARE LA COMPLETEZZA
                DELL'OFFERTA
              </h3>
            </>
          )}

          {offersResumeSelected && (
            <>
              <div className="off-an-loading">
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<QueryStatsOutlinedIcon />}
                  disabled={VAL_Flag}
                  onClick={handleValutazioneOFF}
                >
                  ESEGUI VALUTAZIONE
                </Button>
              </div>

              {loadingMultipleOfferAnalysis && (
                <div className="off-an-loading">
                  <BeatLoader
                    color="#1976d2"
                    loading={loadingMultipleOfferAnalysis}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                    CARICAMENTO...
                  </h6>
                </div>
              )}

              {!supplierNOTFoundFlag ? (
                <div style={{ paddingTop: "2%" }}>
                  <EXP_ANALYSIS_OFF_COMPLETE
                    data={completezzaOFF_OBJ}
                    list_name={offersResumeSelected.nome_listino}
                    VAL_Flag={VAL_Flag}
                  />
                </div>
              ) : (
                <h2 className="off-an-error">FORNITORE NON TROVATO!</h2>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default OffersAnalysisComplete;
