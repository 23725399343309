import React from "react";
import "../../../App.css";

// MUI ELEMENTS
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// ICONS
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import BrowserUpdatedOutlinedIcon from "@mui/icons-material/BrowserUpdatedOutlined";

// LOADERS
import BeatLoader from "react-spinners/BeatLoader";

const OffersAnalysisSelection = ({
  offersGroupList,
  offersGroupSelected,
  setOffersGroupSelected,
  offersGroupDownloaded,
  handleSingleOfferDownload,
  handleResetOfferDownload,
  loadingOffersGroupList,
  loadingSingleOffersList,
  setFlagCompletezzaOFF,
  setFlagAnalisiOFF,
}) => {
  return (
    <>
      <div>
        {offersGroupSelected !== null || offersGroupDownloaded !== null ? (
          <>
            <div
              style={{
                paddingTop: "0.2%",
                paddingLeft: "1%",
                display: "inline-block",
                width: "25%",
                textAlign: "left",
              }}
            >
              <Button
                variant="contained"
                color="redtest"
                startIcon={<ChevronLeftOutlinedIcon />}
                onClick={handleResetOfferDownload}
              >
                INDIETRO
              </Button>
            </div>
          </>
        ) : (
          <div
            style={{
              paddingTop: "0.2%",
              display: "inline-block",
              width: "25%",
            }}
          ></div>
        )}
        <div
          style={{
            paddingTop: "0.2%",
            display: "inline-block",
            width: "50%",
            textAlign: "center",
          }}
        >
          <h2 style={{ fontWeight: "bold", color: "#1976d2" }}>
            CONFRONTO OFFERTE
          </h2>
        </div>
        <div
          style={{
            paddingTop: "0.2%",
            paddingRight: "1%",
            display: "inline-block",
            width: "25%",
            textAlign: "right",
          }}
        ></div>
      </div>
      {loadingOffersGroupList ? (
        <div style={{ textAlign: "center", paddingTop: "2%" }}>
          <BeatLoader
            color={"#1976d2"}
            loading={loadingOffersGroupList}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
            CARICAMENTO...
          </h6>
        </div>
      ) : (
        <>
          {offersGroupDownloaded === null && (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={offersGroupList}
              getOptionLabel={(option) => option.anno}
              sx={{
                width: "100%",
                paddingTop: "2%",
                paddingLeft: "35%",
                paddingRight: "35%",
              }}
              renderInput={(params) => (
                <TextField {...params} label="OFFERTE" />
              )}
              value={offersGroupSelected}
              onChange={(e, newValue) => {
                setOffersGroupSelected(newValue);
              }}
            />
          )}
          {offersGroupSelected !== null && offersGroupDownloaded === null && (
            <>
              <div
                style={{
                  paddingTop: "2%",
                  paddingLeft: "35%",
                  paddingRight: "35%",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  startIcon={<BrowserUpdatedOutlinedIcon />}
                  onClick={handleSingleOfferDownload}
                >
                  CARICA SELEZIONATO
                </Button>
              </div>
              {loadingSingleOffersList && (
                <div style={{ textAlign: "center", paddingTop: "2%" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingSingleOffersList}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6
                    style={{
                      fontFamily: "Calibri",
                      color: "#1976d2",
                    }}
                  >
                    CARICAMENTO...
                  </h6>
                </div>
              )}
            </>
          )}
          {offersGroupDownloaded !== null && (
            <>
              <div
                style={{
                  paddingTop: "5%",
                  display: "inline-block",
                  width: "40%",
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  startIcon={<BrowserUpdatedOutlinedIcon />}
                  onClick={() => setFlagCompletezzaOFF(true)}
                >
                  COMPLETEZZA OFFERTE
                </Button>
              </div>

              <div
                style={{
                  paddingTop: "5%",
                  display: "inline-block",
                  width: "10%",
                  textAlign: "center",
                }}
              ></div>

              <div
                style={{
                  paddingTop: "5%",
                  display: "inline-block",
                  width: "40%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  startIcon={<BrowserUpdatedOutlinedIcon />}
                  onClick={() => setFlagAnalisiOFF(true)}
                >
                  ANALISI OFFERTE
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default OffersAnalysisSelection;
