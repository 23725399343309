import React, { useState, useEffect } from "react";
import "../../../App.css";

// SERVICES
import offersGroupService from "../../../services/offers_group";
import offersResumeService from "../../../services/offers_resume";
import acquistatoService from "../../../services/acquistato";
import garepubService from "../../../services/garapub";

// COMPONENTS
import OffersAnalysisSelection from "./OffersAnalysisSelection";
import OffersAnalysisComplete from "./OffersAnalysisComplete";
import OffersAnalysisDetails from "./OffersAnalysisDetails";

// FUNCTIONS
import { calcoloCosto } from "../../../functions/FUN_CALCOLI";

// POPUPS
import OFF_DETAILS_VALUTATION_POPUP from "../../../popup/OFFER_POPUP/OFF_DETAILS_VALUTATION_POPUP";

// MUI ELEMENTS
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const OffersAnalysisMain = ({ user }) => {
  //*flags per pagine
  const [flagCompletezzaOFF, setFlagCompletezzaOFF] = useState(false);
  const [flagAnalisiOFF, setFlagAnalisiOFF] = useState(false);

  //*gruppi offerte
  const [loadingOffersGroupList, setLoadingOffersGroupList] = useState(true);
  const [offersGroupList, setOffersGroupList] = useState(null);

  const [offersGroupSelected, setOffersGroupSelected] = useState(null);
  const [offersGroupDownloaded, setOffersGroupDownloaded] = useState(null);

  const [loadingSingleOffersList, setLoadingSingleOffersList] = useState(false);

  const [offersListSuppliers, setOffersListSuppliers] = useState(null);

  //*acquistati
  const [loadingPurchasedList, setLoadingPurchasedList] = useState(true);
  const [purchasedList, setPurchasedList] = useState(null);

  //*listini
  const [loadingOffersResumeList, setLoadingOffersResumeList] = useState(true);
  const [offersResumeList, setOffersResumeList] = useState(null);

  //*---

  const [singleOfferSelected, setSingleOfferSelected] = useState(null);
  const [offersResumeSelected, setOffersResumeSelected] = useState(null);
  const [PurchasedSelected, setPurchasedSelected] = useState(null);

  const [completezzaOFF_OBJ, setCompletezzaOFF_OBJ] = useState(null);
  // console.log("completezzaOFF_OBJ", completezzaOFF_OBJ);

  const [loadingMultipleOfferAnalysis, setLoadingMultipleOfferAnalysis] =
    useState(false);

  const [supplierNOTFoundFlag, setSupplierNOTFoundFlag] = useState(false);
  const [VAL_Flag, setVAL_Flag] = useState(false);

  //*---

  const [analisiTipologiaFlag, setAnalisiTipologiaFlag] = useState(false);

  const [loadingAnalisiTipologia, setLoadingAnalisiTipologia] = useState(false);

  const [listinoName, setListinoName] = useState(null);
  const [acquistatoName, setAcquistatoName] = useState(null);
  const [finalAnalysisOBJ, setFinalAnalysisOBJ] = useState(null);

  const [subTabACQ, setSubTabACQ] = useState(null);
  const [subTabLIST, setSubTabLIST] = useState(null);
  const [subTabGARE, setSubTabGARE] = useState(null);
  const [subTabLISTOLD, setSubTabLISTOLD] = useState(null);

  const [subTabOpen, setSubTabOpen] = useState(false);

  const [subTabMAIN, setSubTabMAIN] = useState(null);

  useEffect(() => {
    if (!user.id) return;

    //*Recupera i Gruppi di Offerte
    offersGroupService
      .getOffersGroupByUserId(user.id)
      .then((res) => {
        setOffersGroupList(res.data);
        setLoadingOffersGroupList(false);
      })
      .catch((error) => {
        console.error(
          "Errore durante il recupero delle offerte dal server:",
          error
        );
      });

    //*Recupera i Listini
    offersResumeService
      .getOfferaResumeByUserId(user.id)
      .then((res) => {
        setOffersResumeList(res.data);
        setLoadingOffersResumeList(false);
      })
      .catch((error) => {
        console.error(
          "Errore durante il recupero dei listini dal server:",
          error
        );
      });

    //*Recupera gli Acquistati
    acquistatoService
      .getAcquistatoByUserId(user.id)
      .then((res) => {
        setPurchasedList(res.data);
        setLoadingPurchasedList(false);
      })
      .catch((error) => {
        console.error(
          "Errore durante il recupero degli acquistati dal server:",
          error
        );
      });
  }, [user.id]);

  //*componente selection
  const handleSingleOfferDownload = async () => {
    setLoadingSingleOffersList(true);

    try {
      const response = await offersGroupService.getOffersGroupByOffersGroupId(
        offersGroupSelected.id
      );
      const offersGroup = response.data;

      setOffersGroupDownloaded(offersGroup);

      // Creazione e ordinamento della lista dei fornitori
      const sortedData = offersGroup.offerte_inserite
        .map((selected, index) => ({
          id_temp: index.toString(),
          ragione_sociale: selected.ragione_sociale,
          cf_piva: selected.cf_piva,
        }))
        .sort((a, b) => a.ragione_sociale.localeCompare(b.ragione_sociale));

      setOffersListSuppliers(sortedData);
    } catch (error) {
      console.error("Errore durante il download delle offerte:", error);
    } finally {
      setLoadingSingleOffersList(false);
    }
  };

  const handleResetOfferDownload = () => {
    setOffersGroupSelected(null);
    setOffersGroupDownloaded(null);
    setOffersListSuppliers(null);
  };
  //*--

  const handleBackToOfferSEL = () => {
    setFlagCompletezzaOFF(false);
    setFlagAnalisiOFF(false);

    setSingleOfferSelected(null);

    setVAL_Flag(false);
    setSupplierNOTFoundFlag(false);
    setCompletezzaOFF_OBJ(null);
    setOffersResumeSelected(null);

    setFinalAnalysisOBJ(null);
    setListinoName(null);
    setAcquistatoName(null);
    setAnalisiTipologiaFlag(false);
  };

  const createOffersResumeView = async (offersResumeTEMP) => {
    let finalOBJ = [];
    let id_count = 0;
    await offersResumeTEMP.offerte_inserite.map((OFF_SEL) => {
      OFF_SEL.offerta_obj.map((SINGLE_OFF_SEL) => {
        let temp = {
          id_temp: id_count,

          ragione_sociale: OFF_SEL.ragione_sociale,
          cf_piva: OFF_SEL.cf_piva,
          data_inserimento: OFF_SEL.data_inserimento,
          ordine_min: OFF_SEL.ordine_min,

          aic: SINGLE_OFF_SEL.aic,
          atc: SINGLE_OFF_SEL.atc,
          atc_complementare: SINGLE_OFF_SEL.atc_complementare,
          codice_gruppo_ospedaliero: SINGLE_OFF_SEL.codice_gruppo_ospedaliero,
          data_protocollo: SINGLE_OFF_SEL.data_protocollo,
          date: SINGLE_OFF_SEL.date,
          descrizione_atc: SINGLE_OFF_SEL.descrizione_atc,
          descrizione_gruppo_ospedaliero:
            SINGLE_OFF_SEL.descrizione_gruppo_ospedaliero,
          dosaggio: SINGLE_OFF_SEL.dosaggio,
          fascia: SINGLE_OFF_SEL.fascia,
          filef_flag: SINGLE_OFF_SEL.filef_flag,
          forma_farmaceutica: SINGLE_OFF_SEL.forma_farmaceutica,
          is_checked: SINGLE_OFF_SEL.is_checked,
          iva: SINGLE_OFF_SEL.iva,
          nome_commerciale: SINGLE_OFF_SEL.nome_commerciale,
          numero_protocollo: SINGLE_OFF_SEL.numero_protocollo,
          numero_unita_confezione: SINGLE_OFF_SEL.numero_unita_confezione,
          prezzo_netto_exfactory_confezione:
            SINGLE_OFF_SEL.prezzo_netto_exfactory_confezione,
          prezzo_netto_exfactory_unitario:
            SINGLE_OFF_SEL.prezzo_netto_exfactory_unitario,
          prezzo_pub_confezione: SINGLE_OFF_SEL.prezzo_pub_confezione,
          prezzo_pub_unitario: SINGLE_OFF_SEL.prezzo_pub_unitario,
          prezzo_unitario: SINGLE_OFF_SEL.prezzo_unitario,
          principio_attivo: SINGLE_OFF_SEL.principio_attivo,
          sconto_precentuale_prezzo_pub:
            SINGLE_OFF_SEL.sconto_precentuale_prezzo_pub,
        };

        id_count = id_count + 1;

        finalOBJ.push(temp);
        return OFF_SEL.offerta_obj;
      });

      return offersResumeTEMP.offerte_inserite;
    });

    return finalOBJ;
  };

  //*componente complete

  const handleSelectSingleOffer = async (SupplierSelected) => {
    if (SupplierSelected !== null) {
      const selectedOffer = offersGroupDownloaded.offerte_inserite.find(
        (OFF_SEL) =>
          OFF_SEL.ragione_sociale === SupplierSelected.ragione_sociale
      );

      setSingleOfferSelected(selectedOffer || {});
    }
  };

  const handleSelectOffersResume = (newValue) => {
    setOffersResumeSelected(newValue);
    setCompletezzaOFF_OBJ(null);
    setVAL_Flag(false);
  };

  const createOfferListSuppSel = (offerList, suppSel) =>
    offerList.filter((SEL) => SEL.ragione_sociale === suppSel);

  const handleValutazioneOFF = async () => {
    setLoadingMultipleOfferAnalysis(true);

    try {
      const singleOfferListTEMP = [...singleOfferSelected.offerta_obj];

      const ListVAL_TEMP =
        await offersResumeService.getOffersResumetByOffersResumetId(
          offersResumeSelected.id
        );

      const OFFERS_LIST_TEMP = await createOffersResumeView(ListVAL_TEMP.data);

      const offerListTEMP = await createOfferListSuppSel(
        OFFERS_LIST_TEMP,
        singleOfferSelected?.ragione_sociale
      );

      if (offerListTEMP.length === 0) {
        setSupplierNOTFoundFlag(true);
        setVAL_Flag(true);
        setLoadingMultipleOfferAnalysis(false);
        return;
      }

      const TROVATI = [];
      const NON_TROVATI = [];
      const NUOVI = [];

      const { date, numero_protocollo } =
        singleOfferSelected.offerta_obj[0] || {};
      const dataInizioOFF = date?.[0];
      const dataFineOFF = date?.[1];

      const findByAIC = (list, aic) => list.some((item) => item.aic === aic);

      offerListTEMP.forEach((selected) => {
        const matchingOffer = singleOfferListTEMP.find(
          (offer) => offer.aic === selected.aic
        );

        if (matchingOffer) {
          TROVATI.push({
            aic: matchingOffer.aic,
            dataInizio_offerta: dataInizioOFF,
            dataFine_offerta: dataFineOFF,
            numero_protocollo,
            nome_commerciale: matchingOffer.nome_commerciale,
            atc: matchingOffer.atc,
            atc_complementare: matchingOffer.atc_complementare,
            descrizione_atc: matchingOffer.descrizione_atc,
            prezzo_offerta: matchingOffer.prezzo_unitario,
            principio_attivo: matchingOffer.principio_attivo,
            descrizione_gruppo_ospedaliero:
              matchingOffer.descrizione_gruppo_ospedaliero,
            prezzo_offertaList: selected.prezzo_unitario,
          });
        } else {
          NON_TROVATI.push(selected);
        }
      });

      singleOfferListTEMP.forEach((offer) => {
        if (!findByAIC(TROVATI, offer.aic)) {
          NUOVI.push(offer);
        }
      });

      setCompletezzaOFF_OBJ({
        FORNITORE: singleOfferSelected.ragione_sociale,
        TROVATI,
        NON_TROVATI,
        NUOVI,
      });

      setSupplierNOTFoundFlag(false);
    } catch (error) {
      console.error("Error during evaluation:", error);
    } finally {
      setVAL_Flag(true);
      setLoadingMultipleOfferAnalysis(false);
    }
  };

  //*componente details

  const foundMIN_GP_OSP = async (
    offersWithGP_OSP,
    purchasedTEMP,
    offerListTEMP
  ) => {
    // Mappa gruppo offerte
    offersWithGP_OSP.forEach((Selected) => {
      let ACQ_OSP_LIST = [];
      let OFF_OSP_LIST = [];
      let OFF_OLD_OSP_LIST = [];

      purchasedTEMP.acquistato_inserito.forEach((SelectedACQ) => {
        if (
          Selected.codice_gruppo_ospedaliero ===
          SelectedACQ.codice_gruppo_ospedaliero
        ) {
          ACQ_OSP_LIST.push(SelectedACQ);
        }
      });

      if (ACQ_OSP_LIST.length > 0) {
        ACQ_OSP_LIST.sort((a, b) => a.prezzo_unitario - b.prezzo_unitario);
      }

      offersWithGP_OSP.forEach((SelectedOFF) => {
        if (
          Selected.descrizione_gruppo_ospedaliero ===
          SelectedOFF.descrizione_gruppo_ospedaliero
        ) {
          OFF_OSP_LIST.push(SelectedOFF);
        }
      });

      if (OFF_OSP_LIST.length > 0) {
        OFF_OSP_LIST.sort((a, b) => a.prezzo_unitario - b.prezzo_unitario);
      }

      offerListTEMP.forEach((SelectedOFF) => {
        if (
          Selected.descrizione_gruppo_ospedaliero ===
          SelectedOFF.descrizione_gruppo_ospedaliero
        ) {
          OFF_OLD_OSP_LIST.push(SelectedOFF);
        }
      });

      if (OFF_OLD_OSP_LIST.length > 0) {
        OFF_OLD_OSP_LIST.sort((a, b) => a.prezzo_unitario - b.prezzo_unitario);
      }

      Selected.gp_osp_min_acquistato_LISTA = ACQ_OSP_LIST;
      Selected.gp_osp_min_listino_offerte_LISTA = OFF_OSP_LIST;
      Selected.gp_osp_min_listino_old_offerte_LISTA = OFF_OLD_OSP_LIST;
    });

    const GP_OSP_List = offersWithGP_OSP
      .filter((Selected) => Selected.codice_gruppo_ospedaliero !== "")
      .map((Selected) => Selected.codice_gruppo_ospedaliero);

    const GPSOPList_DoppiRimossi = [...new Set(GP_OSP_List)];

    const GP_List_GPOSP = await garepubService.getGPOSP(GPSOPList_DoppiRimossi);

    offersWithGP_OSP.forEach((Selected) => {
      let GP_OSP_LIST_FIN = [];

      GP_List_GPOSP.forEach((element) => {
        if (
          element.data.length > 0 &&
          element.data[0].codice_gruppo_ospedaliero ===
            Selected.codice_gruppo_ospedaliero
        ) {
          element.data.sort((a, b) => a.prezzo_unitario - b.prezzo_unitario);
          GP_OSP_LIST_FIN = element.data;
        }
      });

      Selected.gp_osp_min_gare_pub_LISTA = GP_OSP_LIST_FIN;
    });

    return offersWithGP_OSP;
  };

  const handleAnalisiTipologia = async () => {
    setLoadingAnalisiTipologia(true);

    try {
      //*Recupera l'ACQ selezionato e il Listino crea un riassunto delle offerte inserite
      const [OFFERS_GROUP_TEMP, OFFERS_LIST_TEMP, PURCHASED_TEMP] =
        await Promise.all([
          createOffersResumeView(offersGroupDownloaded),
          offersResumeService.getOffersResumetByOffersResumetId(
            offersResumeSelected.id
          ),
          acquistatoService.getAcquistatoByPurchasedId(PurchasedSelected.id),
        ]);

      const OFFERS_LIST_TEMP_ELAB = await createOffersResumeView(
        OFFERS_LIST_TEMP.data
      );

      //*Divide le offerte in con/senza gruppo ospedaliero
      let senzaGP_OSP = [];
      let conGP_OSP = [];

      OFFERS_GROUP_TEMP.forEach((SelectedOFF) => {
        if (!SelectedOFF.descrizione_gruppo_ospedaliero) {
          senzaGP_OSP.push(SelectedOFF);
        } else {
          conGP_OSP.push(SelectedOFF);
        }
      });

      //*Crea oggetto strutturato per le offerte senza G.O.
      let OFF_SENZA_GP_OSP = senzaGP_OSP.map((Selected) => ({
        id_temp: Selected.id_temp,
        fornitore: {
          ragione_sociale: Selected.ragione_sociale,
          cf_piva: Selected.cf_piva,
        },
        numero_protocollo: Selected.numero_protocollo,
        data_inizio: Selected.date[0],
        data_fine: Selected.date[1],
        aic: Selected.aic,
        nome_commerciale: Selected.nome_commerciale,
        atc: Selected.atc,
        atc_complementare: Selected.atc_complementare,
        descrizione_atc: Selected.descrizione_atc,
        principio_attivo: Selected.principio_attivo,
        codice_gruppo_ospedaliero: null,
        descrizione_gruppo_ospedaliero: null,
        prezzo_unitario: Math.round(Selected.prezzo_unitario * 100000) / 100000,
        gp_osp_min_acquistato_LISTA: [],
        gp_osp_min_listino_offerte_LISTA: [],
        gp_osp_min_gare_pub_LISTA: [],
      }));

      //*Elebarora in qualche modo le offerte con G.O.
      const listMIN_GP_OSP = await foundMIN_GP_OSP(
        conGP_OSP,
        PURCHASED_TEMP.data,
        OFFERS_LIST_TEMP_ELAB
      );

      //*Unisce i due oggetti elaborati
      let OBJ_ELAB = listMIN_GP_OSP.concat(OFF_SENZA_GP_OSP);

      // console.log("OBJ_ELAB", OBJ_ELAB);

      //?CALCOLI COSTI OK, IL PROBLEMA STA NEL CALCOLO DELLE DIFFERENZES

      //*Calcolo Costi
      OBJ_ELAB.forEach((selectedOff) => {
        let quantitaTotale = 0;
        let costoTotale = 0;

        let conteggioAcquisti = 0;

        if (selectedOff.descrizione_gruppo_ospedaliero != null) {
          if (selectedOff.gp_osp_min_acquistato_LISTA.length > 0) {
            selectedOff.gp_osp_min_acquistato_LISTA.forEach((acquisto) => {
              let costoTemp = calcoloCosto(
                acquisto?.prezzo_unitario,
                acquisto?.quantita
              );

              costoTotale += costoTemp;
              quantitaTotale += acquisto?.quantita;
              conteggioAcquisti += 1;

              acquisto.costo_TEMP = costoTemp;
              acquisto.diff_TEMP = calcoloDifferenzaEXP(
                costoTemp,
                calcoloCosto(selectedOff?.prezzo_unitario, acquisto?.quantita)
              );
              acquisto.diff_UNITARIA = calcoloDifferenzaUnitariaEXP(
                selectedOff?.prezzo_unitario,
                acquisto?.prezzo_unitario
              );
            });

            if (conteggioAcquisti > 1) {
              selectedOff.gp_osp_min_acquistato_LISTA.push({
                _id: 1,
                aic: "TOT",
                nome_commerciale: null,
                fornitore: null,
                prezzo_unitario: null,
                diff_unitaria_TEMP: null,
                costo_TEMP: costoTotale,
                diff_TEMP: null,
                quantita: quantitaTotale,
              });
            }

            selectedOff.gp_osp_min_listino_offerte_LISTA.forEach((sel) => {
              sel.quantita_tot = quantitaTotale;
            });

            selectedOff.gp_osp_min_listino_old_offerte_LISTA.forEach((sel) => {
              sel.quantita_tot = quantitaTotale;
            });

            selectedOff.gp_osp_min_gare_pub_LISTA.forEach((sel) => {
              sel.quantita_tot = quantitaTotale;
            });

            selectedOff.quantita_tot = quantitaTotale;
          } else {
            selectedOff.gp_osp_min_listino_offerte_LISTA.forEach((sel) => {
              sel.quantita_tot = null;
            });
            selectedOff.gp_osp_min_listino_old_offerte_LISTA.forEach((sel) => {
              sel.quantita_tot = null;
            });
            selectedOff.gp_osp_min_gare_pub_LISTA.forEach((sel) => {
              sel.quantita_tot = null;
            });
          }
        }
      });

      // console.log("OBJ_ELAB", OBJ_ELAB);

      setFinalAnalysisOBJ(OBJ_ELAB);

      setAcquistatoName(PURCHASED_TEMP.data.nome_salvataggio);
      setListinoName(OFFERS_LIST_TEMP.data.nome_listino);
      setAnalisiTipologiaFlag(true);
    } catch (error) {
      console.error("Error in handleAnalisiTipologia:", error);
    } finally {
      setLoadingAnalisiTipologia(false);
    }
  };

  const handleViewDetailsTab = (params) => {
    let new_obj = { ...params.row };

    console.log("new_obj", new_obj);

    if (new_obj[0]?.descrizione_gruppo_ospedaliero !== null) {
      //?CASO CON GRUPPO OSPEDALIERO

      setSubTabMAIN([new_obj]);

      if (new_obj?.gp_osp_min_acquistato_LISTA.length > 0) {
        let acq_temp = [...new_obj?.gp_osp_min_acquistato_LISTA];

        acq_temp.forEach((acq_SEL) => {
          acq_SEL.costo_PRINCIPALE = calcoloCosto(
            new_obj?.prezzo_unitario,
            new_obj?.quantita_tot
          );
        });
        setSubTabACQ(acq_temp);
      }

      if (new_obj?.gp_osp_min_listino_offerte_LISTA.length > 0) {
        let list_temp = [...new_obj?.gp_osp_min_listino_offerte_LISTA];

        list_temp.forEach((list_SEL) => {
          list_SEL.costo_PRINCIPALE = calcoloCosto(
            new_obj?.prezzo_unitario,
            new_obj?.quantita_tot
          );
        });
        setSubTabLIST(list_temp);
      }

      if (new_obj?.gp_osp_min_gare_pub_LISTA.length > 0) {
        let gare_temp = [...new_obj?.gp_osp_min_gare_pub_LISTA];

        gare_temp.forEach((gare_SEL) => {
          gare_SEL.costo_PRINCIPALE = calcoloCosto(
            new_obj?.prezzo_unitario,
            new_obj?.quantita_tot
          );
        });
        setSubTabGARE(gare_temp);
      }

      if (new_obj?.gp_osp_min_listino_old_offerte_LISTA.length > 0) {
        let list_temp = [...new_obj?.gp_osp_min_listino_old_offerte_LISTA];

        list_temp.forEach((list_SEL) => {
          list_SEL.costo_PRINCIPALE = calcoloCosto(
            new_obj?.prezzo_unitario,
            new_obj?.quantita_tot
          );
        });
        setSubTabLISTOLD(list_temp);
      }
    } else {
      //   //?CASO SENZA GRUPPO OSPEDALIERO
      setSubTabMAIN(new_obj);
      setSubTabACQ(null);
      setSubTabLIST(null);
      setSubTabGARE(null);
      setSubTabLISTOLD(null);
    }

    setSubTabOpen(true);
  };
  //*--

  const calcoloDifferenzaEXP = (VecchioCosto, NuovoCosto) => {
    let diff = NuovoCosto - VecchioCosto;
    diff = Math.round(diff * 100) / 100;

    return diff;
  };
  const calcoloDifferenzaUnitariaEXP = (PrezzoSelezionato, AltroPrezzo) => {
    // console.log("PrezzoSelezionato", PrezzoSelezionato);
    // console.log("AltroPrezzo", AltroPrezzo);

    let diff = PrezzoSelezionato - AltroPrezzo;
    diff = Math.round(diff * 100000) / 100000;

    // console.log("diff", diff);

    return diff;
  };

  const handleSubTabClose = () => {
    setSubTabMAIN(null);
    setSubTabACQ(null);
    setSubTabLIST(null);
    setSubTabGARE(null);
    setSubTabLISTOLD(null);

    setSubTabOpen(false);
  };

  return (
    <>
      <div className="singleOfferPage">
        <ThemeProvider theme={theme}>
          {" "}
          {!flagAnalisiOFF &&
            (flagCompletezzaOFF ? (
              <OffersAnalysisComplete
                handleBackToOfferSEL={handleBackToOfferSEL}
                handleSelectSingleOffer={handleSelectSingleOffer}
                handleSelectOffersResume={handleSelectOffersResume}
                handleValutazioneOFF={handleValutazioneOFF}
                singleOfferSelected={singleOfferSelected}
                offersListSuppliers={offersListSuppliers}
                loadingOffersResumeList={loadingOffersResumeList}
                loadingMultipleOfferAnalysis={loadingMultipleOfferAnalysis}
                offersResumeList={offersResumeList}
                offersResumeSelected={offersResumeSelected}
                VAL_Flag={VAL_Flag}
                supplierNOTFoundFlag={supplierNOTFoundFlag}
                completezzaOFF_OBJ={completezzaOFF_OBJ}
              />
            ) : (
              //* Main
              <OffersAnalysisSelection
                offersGroupList={offersGroupList}
                offersGroupSelected={offersGroupSelected}
                setOffersGroupSelected={setOffersGroupSelected}
                offersGroupDownloaded={offersGroupDownloaded}
                handleSingleOfferDownload={handleSingleOfferDownload}
                handleResetOfferDownload={handleResetOfferDownload}
                loadingOffersGroupList={loadingOffersGroupList}
                loadingSingleOffersList={loadingSingleOffersList}
                setFlagCompletezzaOFF={setFlagCompletezzaOFF}
                setFlagAnalisiOFF={setFlagAnalisiOFF}
              />
            ))}
          {flagAnalisiOFF && !flagCompletezzaOFF && (
            <OffersAnalysisDetails
              handleBackToOfferSEL={handleBackToOfferSEL}
              analisiTipologiaFlag={analisiTipologiaFlag}
              loadingOffersGroupList={loadingOffersGroupList}
              offersResumeList={offersResumeList}
              offersResumeSelected={offersResumeSelected}
              setOffersResumeSelected={setOffersResumeSelected}
              loadingPurchasedList={loadingPurchasedList}
              purchasedList={purchasedList}
              setPurchasedSelected={setPurchasedSelected}
              PurchasedSelected={PurchasedSelected}
              handleAnalisiTipologia={handleAnalisiTipologia}
              handleViewDetailsTab={handleViewDetailsTab}
              loadingAnalisiTipologia={loadingAnalisiTipologia}
              finalAnalysisOBJ={finalAnalysisOBJ}
            />
          )}
          <OFF_DETAILS_VALUTATION_POPUP
            subTabOpen={subTabOpen}
            handleSubTabClose={handleSubTabClose}
            listinoName={listinoName}
            acquistatoName={acquistatoName}
            subTabMAIN={subTabMAIN}
            subTabACQ={subTabACQ}
            subTabLIST={subTabLIST}
            subTabGARE={subTabGARE}
            subTabLISTOLD={subTabLISTOLD}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default OffersAnalysisMain;
