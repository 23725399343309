import React, { useState, useEffect } from "react";
import "../../App.css";

import axios from "axios";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import yearsJSON from "../../utils/yearsJSON.json";

import OFF_CREATE_NEW_GROUP_POPUP from "../../popup/OFFERTE_NEW/OFF_CREATE_NEW_GROUP_POPUP";

import SingleOfferForm from "./SingleOfferForm";
import SingleOfferImport from "./SingleOfferImport";

import SingleOfferImportChange from "./SingleOfferImportChange";

import OFFER_NEW_SAVE_POPUP from "../../popup/NEW_OFFER_POPUP/OFFER_NEW_SAVE_POPUP";
import OFFER_CHANGE_SAVE_POPUP from "../../popup/NEW_OFFER_POPUP/OFFER_CHANGE_SAVE_POPUP";

import OFFER_CREATE_OFFERSRESUME_POPUP from "../../popup/NEW_OFFER_POPUP/OFFER_CREATE_OFFERSRESUME_POPUP";

import EXP_OFF_MAIN_COMPLETE from "../../exports/OFF/EXP_OFF_MAIN_COMPLETE";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import BrowserUpdatedOutlinedIcon from "@mui/icons-material/BrowserUpdatedOutlined";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import offersGroupService from "../../services/offers_group";
import offersGroupChangeService from "../../services/offers_group_change";
import offersResumeService from "../../services/offers_resume";

import BeatLoader from "react-spinners/BeatLoader";

import { SINGLE_OFF_MAIN_COLUMNS } from "../../colums/SINGLE_OFF_COLUMNS";

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

dayjs.extend(dayjsPluginUTC);

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const OfferInsertMain = ({ user }) => {
  const [loadingOffersGroupList, setLoadingOffersGroupList] = useState(true);

  const [offersGroupList, setOffersGroupList] = useState([]);

  const [yearList, setYearList] = useState(null);

  const [yearSel, setYearSel] = useState(null);

  const [saveNewGroupOpen, setSaveNewGroupOpen] = useState(false);

  const [updateTrigger, setUpdateTrigger] = useState(0);

  const [salvaOffersGroup_ERR, setSalvaOffersGroup_ERR] = useState(false);

  const [loadingCreateListSave, setLoadingCreateListSave] = useState(false);

  const [loadingOfferSList, setLoadingOffersList] = useState(false);

  const [offersListDownloaded, setOffersListDownloaded] = useState(null);

  const [offersListSuppliers, setOffersListSuppliers] = useState(null);

  const [offerFormData, setOfferFormData] = useState(null);
  console.log("offerFormData", offerFormData);
  const [offerDataComplete, setOfferDataComplete] = useState(null);
  // console.log("offerDataComplete", offerDataComplete);

  const [saveNewOfferOpen, setSaveNewOfferOpen] = useState(false);
  const [loadingSaveSingleOffer, setLoadingSaveSingleOffer] = useState(false);

  const [changeOfferFlag, setChangeOfferFlag] = useState(false);

  const [confirmChangeOFF, setConfirmChangeOFF] = useState(false);

  const [changeOfferOpen, setChangeOfferOpen] = useState(false);
  const [loadingChangeSingleOffer, setLoadingChangeSingleOffer] =
    useState(false);

  const [changeSaveError, setChangeSaveError] = useState(false);

  const [createOffersResumeOpen, setCreateOffersResumeOpen] = useState(false);
  const [fileSavedName, setFileSavedName] = useState(null);
  const [errors, setErrors] = useState(false);
  const [salvaOffersresume_ERR, setSalvaOffersresume_ERR] = useState(false);
  const [loadingSaveOffersResume, setLoadingSaveOffersResume] = useState(false);

  // const [pdf, setPdf] = useState(null);
  // console.log("pdf", pdf);
  // const [offertaId, setOffertaId] = useState('');
  const [pdfUrl, setPdfUrl] = useState("");
  // console.log("pdfUrl", pdfUrl);

  const [file, setFile] = useState(null);
  // console.log("file", file);

  const [pdfFlagOK, setPdfFlagOK] = useState(false);
  // console.log("pdfFlagOK", pdfFlagOK);

  const [loadingPDF, setLoadingPDF] = useState(false);
  // console.log("pdfFlagOK", pdfFlagOK);

  useEffect(() => {
    if (!user.id) return;

    offersGroupService.getOffersGroupByUserId(user.id).then((res) => {
      setOffersGroupList(res.data);
      setLoadingOffersGroupList(false);
    });

    setYearList(yearsJSON);
    setYearSel(yearsJSON.find((y) => y.id === "2024"));
  }, [updateTrigger, user.id]);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onFileUpload = async () => {
    setLoadingPDF(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await axios.post(
        "http://localhost:3001/api/files/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setPdfUrl(`http://localhost:3001/api/files/file/${res.data.filename}`);
      // console.log(res.data);
      setPdfFlagOK(true);
    } catch (err) {
      setPdfFlagOK(false);
      console.error(err);
    } finally {
      setLoadingPDF(false);
    }
  };

  const handleSaveSingleOfferPopupClose = () => {
    setSaveNewOfferOpen(false);
    setFile(null);
    setPdfUrl("");
  };

  const visualizzaDataOra = (DataDaElaborare) => {
    let Data_Ora = DataDaElaborare.split("T");

    let DataDivisa = Data_Ora[0].split("-");

    const DataInvertita =
      DataDivisa[2] + "/" + DataDivisa[1] + "/" + DataDivisa[0];

    const OraTagliata = Data_Ora[1].slice(0, 5);

    const finalString = DataInvertita + " - " + OraTagliata;

    return finalString;
  };

  const handleSaveNewOfferList = async (OBJ_ToSave, OBJ_ToSave_Change) => {
    setLoadingCreateListSave(true);

    try {
      await offersGroupService.setToken(user.token);
      await offersGroupChangeService.setToken(user.token);

      const response1 = await offersGroupService.salvaOffersGroup(OBJ_ToSave);
      if (response1 === "error") {
        throw new Error("Errore nel salvataggio del gruppo di offerte.");
      }

      const response2 = await offersGroupChangeService.salvaOffersGroupChange(
        OBJ_ToSave_Change
      );
      if (response2 === "error") {
        throw new Error(
          "Errore nel salvataggio delle modifiche al gruppo di offerte."
        );
      }

      // Se entrambi i salvataggi vanno a buon fine, resetta eventuali errori precedenti
      setSalvaOffersGroup_ERR(false);
    } catch (error) {
      console.error(error.message);
      // Imposta l'errore di salvataggio del gruppo di offerte
      setSalvaOffersGroup_ERR(true);
    } finally {
      // Disattiva il loader dopo il tentativo di salvataggio
      setLoadingCreateListSave(false);
    }
  };

  const handleCreateNewOfferList = async () => {
    let OBJ_ToSave = {
      anno: yearSel.anno,
      ultimo_listino: null,
      ultima_modifica: null,
      offerte_inserite: [],
    };

    let OBJ_ToSave_Changes = {
      anno: yearSel.anno,
      ultima_modifica: dayjs()
        .utc()
        .add(2, "hour")
        .local()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      offerte_modificate: [],
    };

    await handleSaveNewOfferList(OBJ_ToSave, OBJ_ToSave_Changes);

    setSaveNewGroupOpen(false);
    setUpdateTrigger((prev) => prev + 1);
  };

  const handleOfferListDownload = async (OFF_LIST_TEMP) => {
    setLoadingOffersList(true);

    let suppList_TEMP = [];

    await offersGroupService
      .getOffersGroupByOffersGroupId(OFF_LIST_TEMP.id)
      .then((res) => {
        setOffersListDownloaded(res.data);

        let id_count = 0;

        res.data.offerte_inserite.map((Selected) => {
          let temp = {
            id_temp: id_count.toString(),
            ragione_sociale: Selected.ragione_sociale,
            cf_piva: Selected.cf_piva,
          };
          suppList_TEMP.push(temp);
          id_count = id_count + 1;
          return res.data.offerte_inserite;
        });

        setOffersListSuppliers(suppList_TEMP);
        setLoadingOffersList(false);
      });
  };

  const handdleControlYear = async () => {
    let OFF_LIST_TEMP = null;

    await offersGroupList.map((SEL) => {
      if (yearSel.anno === SEL.anno) {
        OFF_LIST_TEMP = { ...SEL };
      }
      return offersGroupList;
    });

    if (OFF_LIST_TEMP === null) {
      setSaveNewGroupOpen(true);
    } else {
      await handleOfferListDownload(OFF_LIST_TEMP);
    }
  };

  const updateOfferData = (newOfferData, newSupplierFlag) => {
    setOfferFormData(newOfferData);
    setChangeOfferFlag(newSupplierFlag);
  };

  const updateDataFileChecked = (newFileData) => {
    let finalFileData = {};

    finalFileData.fornitore = offerFormData?.supplier;
    finalFileData.data_protocollo = offerFormData?.protocolDate;
    finalFileData.date = [offerFormData?.firstDate, offerFormData?.lastDate];
    finalFileData.numero_protocollo = offerFormData?.protocolNumber;
    finalFileData.ordine_min = offerFormData?.minOrder;
    finalFileData.pdf_offerta_id = null;
    // finalFileData.pdf_offerta_id = offerFormData?.pdfFile;

    finalFileData.offerta_inserita = newFileData;

    setOfferDataComplete(finalFileData);
  };

  const handleBackToInsertOffer = () => {
    setOfferFormData(null);
    setOfferDataComplete(null);
    setConfirmChangeOFF(false);
    setSaveNewOfferOpen(false);
  };

  const handleBackButton = () => {
    if (offerFormData !== null || offerDataComplete !== null) {
      handleBackToInsertOffer();
    } else {
      setOffersListDownloaded(null);
    }
  };

  //----

  const handleSaveSingleOffer = async (pdf_file_ID) => {
    setLoadingSaveSingleOffer(true);

    try {
      let TO_SAVE = { ...offerDataComplete };
      let TO_CHANGE = { ...offersListDownloaded };

      let offer_elab = TO_SAVE.offerta_inserita.map((Selected) => ({
        //Farmadati
        aic: Selected.aic,
        nome_commerciale: Selected.nome_commerciale,
        principio_attivo: Selected.principio_attivo,
        atc: Selected.atc,
        atc_complementare: Selected.atc_complementare,
        descrizione_atc: Selected.descrizione_atc,
        codice_gruppo_ospedaliero: Selected.codice_gruppo_ospedaliero,
        descrizione_gruppo_ospedaliero: Selected.descrizione_gruppo_ospedaliero,
        iva: Selected.iva,
        forma_farmaceutica: Selected.forma_farmaceutica,
        dosaggio: Selected.dosaggio,
        fascia: Selected.fascia,
        //Dal file
        prezzo_unitario: Selected.prezzo_unitario,
        prezzo_pub_confezione: Selected.prezzo_pub_confezione,
        numero_unita_confezione: Selected.numero_unita_confezione,
        sconto_precentuale_prezzo_pub: Selected.sconto_precentuale,
        prezzo_pub_unitario: Selected.prezzo_pub_unitario,
        prezzo_netto_exfactory_confezione:
          Selected.prezzo_netto_exfactory_confezione,
        prezzo_netto_exfactory_unitario:
          Selected.prezzo_netto_exfactory_unitario,
        date: TO_SAVE.date,
        numero_protocollo: Selected.numero_protocollo,
        data_protocollo: Selected.data_protocollo,
        //Flags
        filef_flag: false,
        is_checked: false,
      }));

      let NEW_OFFER = {
        ragione_sociale: TO_SAVE.fornitore.ragione_sociale,
        cf_piva: TO_SAVE.fornitore.cf_piva,
        data_inserimento: dayjs()
          .utc()
          .add(2, "hour")
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        ordine_min: TO_SAVE.ordine_min,
        pdf_offerta_id: pdf_file_ID !== "" ? pdf_file_ID : null,
        offerta_obj: offer_elab,
      };

      TO_CHANGE.ultima_modifica = dayjs()
        .utc()
        .add(2, "hour")
        .local()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      TO_CHANGE.offerte_inserite.push(NEW_OFFER);

      await offersGroupService.setToken(user.token);

      const res = await offersGroupService.aggiornaOffersGroup(
        TO_CHANGE,
        TO_CHANGE.id
      );

      setOffersListDownloaded(res.data);

      let suppList_TEMP = [];
      let id_count = 0;

      res.data.offerte_inserite.map((Selected) => {
        let temp = {
          id_temp: id_count.toString(),
          ragione_sociale: Selected.ragione_sociale,
          cf_piva: Selected.cf_piva,
        };
        suppList_TEMP.push(temp);
        id_count = id_count + 1;
        return res.data.offerte_inserite;
      });

      setOffersListSuppliers(suppList_TEMP);
      setSaveNewOfferOpen(false);
      setCreateOffersResumeOpen(true);
    } catch (error) {
      console.error(
        "Errore durante il salvataggio dell'offerta:",
        error.message
      );
      // Mostra un messaggio di errore all'utente
      alert(
        "Si è verificato un errore durante il salvataggio dell'offerta. Riprova."
      );
    } finally {
      // Assicurati che il loader venga disattivato
      setLoadingSaveSingleOffer(false);
    }
  };

  const OfferGroupUpdate = (listTEMP, nuovoOggetto) => {
    let array = [...listTEMP.offerte_inserite];

    const indice = array.findIndex(
      (obj) => obj.ragione_sociale === nuovoOggetto.ragione_sociale
    );

    if (indice !== -1) {
      listTEMP.offerte_inserite = array.map((obj, idx) =>
        idx === indice ? nuovoOggetto : obj
      );
      return listTEMP;
    } else {
      listTEMP.offerte_inserite.push(nuovoOggetto);
      return listTEMP;
    }
  };

  const handleChangeSingleOffer = async (pdf_file_ID) => {
    setLoadingChangeSingleOffer(true);

    let TO_SAVE = { ...offerDataComplete };

    let offer_elab = TO_SAVE.offerta_inserita.map((Selected) => ({
      //Farmadati
      aic: Selected.aic,
      nome_commerciale: Selected.nome_commerciale,
      principio_attivo: Selected.principio_attivo,
      atc: Selected.atc,
      atc_complementare: Selected.atc_complementare,
      descrizione_atc: Selected.descrizione_atc,
      codice_gruppo_ospedaliero: Selected.codice_gruppo_ospedaliero,
      descrizione_gruppo_ospedaliero: Selected.descrizione_gruppo_ospedaliero,
      iva: Selected.iva,
      forma_farmaceutica: Selected.forma_farmaceutica,
      dosaggio: Selected.dosaggio,
      fascia: Selected.fascia,
      //Dal file
      prezzo_unitario: Selected.prezzo_unitario,
      prezzo_pub_confezione: Selected.prezzo_pub_confezione,
      numero_unita_confezione: Selected.numero_unita_confezione,
      sconto_precentuale_prezzo_pub: Selected.sconto_precentuale,
      prezzo_pub_unitario: Selected.prezzo_pub_unitario,
      prezzo_netto_exfactory_confezione:
        Selected.prezzo_netto_exfactory_confezione,
      prezzo_netto_exfactory_unitario: Selected.prezzo_netto_exfactory_unitario,
      date: TO_SAVE.date,
      numero_protocollo: Selected.numero_protocollo,
      data_protocollo: Selected.data_protocollo,
      //Flags
      filef_flag: false,
      is_checked: false,
    }));

    let NEW_OFFER = {
      ragione_sociale: TO_SAVE.fornitore.ragione_sociale,
      cf_piva: TO_SAVE.fornitore.cf_piva,
      data_inserimento: dayjs()
        .utc()
        .add(2, "hour")
        .local()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      ordine_min: TO_SAVE.ordine_min,
      pdf_offerta_id: pdf_file_ID !== "" ? pdf_file_ID : null,
      offerta_obj: offer_elab,
    };

    let OfferGroup_TEMP = { ...offersListDownloaded };

    let OLD_OFFER = OfferGroup_TEMP.offerte_inserite.find(
      (Selected) =>
        Selected.ragione_sociale === TO_SAVE.fornitore.ragione_sociale
    );

    let OfferGroupChange_TEMP = {
      dataAttuale: dayjs()
        .utc()
        .add(2, "hour")
        .local()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      nuovaOfferta: OLD_OFFER,
    };

    let id_change_obj = "";

    await offersGroupChangeService
      .getOffersGroupChangeByOffersGroupYear(offersListDownloaded?.anno)
      .then((res) => {
        id_change_obj = res.data;
      });

    await OfferGroupUpdate(OfferGroup_TEMP, NEW_OFFER);

    OfferGroup_TEMP.ultima_modifica = dayjs()
      .utc()
      .add(2, "hour")
      .local()
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    try {
      setLoadingChangeSingleOffer(true);

      await offersGroupChangeService.setToken(user.token);
      await offersGroupService.setToken(user.token);

      const response1 = await offersGroupService.aggiornaOffersGroup(
        OfferGroup_TEMP,
        OfferGroup_TEMP.id
      );

      if (response1 === "error") {
        throw new Error("Errore nel salvataggio del gruppo di offerte.");
      }

      const response2 =
        await offersGroupChangeService.aggiornaOffersGroupChange(
          OfferGroupChange_TEMP,
          id_change_obj.id
        );
      if (response2 === "error") {
        throw new Error(
          "Errore nel salvataggio delle modifiche al gruppo di offerte."
        );
      }

      // Se entrambi i salvataggi vanno a buon fine, resetta eventuali errori precedenti
      setChangeSaveError(false);
      setChangeOfferOpen(false);
      setCreateOffersResumeOpen(true);
      setOffersListDownloaded(response1.data);
    } catch (error) {
      console.error(error.message);
      // Mostra un messaggio di errore all'utente
      alert(
        "Si è verificato un errore durante il salvataggio dell'offerta. Riprova."
      );
      // Imposta l'errore di salvataggio del gruppo di offerte
      setChangeSaveError(true);
    } finally {
      // Disattiva il loader dopo il tentativo di salvataggio
      setLoadingChangeSingleOffer(false);
    }

    setLoadingChangeSingleOffer(false);
    setChangeOfferOpen(false);

    handleBackToInsertOffer();
  };

  //----

  const handleNotCreateResume = () => {
    setCreateOffersResumeOpen(false);
    // setChangeOfferOpen(false);
    handleBackToInsertOffer();
  };

  const ValidateForm = () => {
    const fileSavedNameCheck = fileSavedName;

    const newErrors = {};

    if (!fileSavedNameCheck || fileSavedNameCheck === "") {
      newErrors.fileSavedName = "Inserire il Nome del Listino";
    }

    return newErrors;
  };

  const handleSaveOfferResume = async () => {
    const formErrors = ValidateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      setLoadingSaveOffersResume(true);

      let OFFER_GROUP_TEMP = { ...offersListDownloaded };

      const OBJ_TO_SAVE = {
        nome_listino: fileSavedName,
        anno: offersListDownloaded?.anno,
        data_creazione: dayjs()
          .utc()
          .add(2, "hour")
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        offerte_inserite: offersListDownloaded?.offerte_inserite,
      };

      OFFER_GROUP_TEMP.ultimo_listino = dayjs()
        .utc()
        .add(2, "hour")
        .local()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      try {
        await offersResumeService.setToken(user.token);
        await offersGroupService.setToken(user.token);

        const response1 = await offersResumeService.salvaOffersResume(
          OBJ_TO_SAVE
        );

        if (response1 === "error") {
          throw new Error("Errore nel salvataggio del gruppo di offerte.");
        }

        const response2 = await offersGroupService.aggiornaOffersGroup(
          OFFER_GROUP_TEMP,
          OFFER_GROUP_TEMP.id
        );
        if (response2 === "error") {
          throw new Error(
            "Errore nel salvataggio delle modifiche al gruppo di offerte."
          );
        }

        // Se entrambi i salvataggi vanno a buon fine, resetta eventuali errori precedenti
        setSalvaOffersresume_ERR(false);
        setCreateOffersResumeOpen(false);
        setOffersListDownloaded(OFFER_GROUP_TEMP);
        handleBackToInsertOffer();
      } catch (error) {
        console.error("error", error);
        // Imposta l'errore di salvataggio del gruppo di offerte
        setSalvaOffersresume_ERR(true);
      } finally {
        // Disattiva il loader dopo il tentativo di salvataggio
        setLoadingSaveOffersResume(false);
      }
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="singleOfferPage">
          {loadingOffersGroupList && (
            <div style={{ textAlign: "center", paddingTop: "3%" }}>
              <BeatLoader
                color={"#1976d2"}
                loading={loadingOffersGroupList}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                CARICAMENTO...
              </h6>
            </div>
          )}
          {!loadingOffersGroupList && (
            <>
              {offersListDownloaded === null && yearList !== null && (
                <>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "5%",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="year"
                      options={yearsJSON}
                      getOptionLabel={(option) => option.anno}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      sx={{
                        mx: "auto",
                        width: "100%",
                        paddingTop: "1%",
                        paddingRight: "40%",
                        paddingLeft: "40%",
                      }}
                      renderOption={(props, option, index) => {
                        const key = `listItem-${index}-${option.id}`;
                        return (
                          <li {...props} key={key}>
                            {option.anno}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="ANNO" />
                      )}
                      value={yearSel}
                      onChange={(e, newValue) => {
                        setYearSel(newValue);
                      }}
                    />
                  </div>
                  {yearSel !== null && (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          paddingTop: "2%",
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        <Button
                          variant="contained"
                          component="label"
                          color="bluetest"
                          startIcon={<BrowserUpdatedOutlinedIcon />}
                          onClick={() => handdleControlYear()}
                        >
                          CARICA OFFERTE {yearSel?.anno}
                        </Button>
                      </div>
                      {loadingOfferSList && (
                        <div style={{ textAlign: "center", paddingTop: "3%" }}>
                          <BeatLoader
                            color={"#1976d2"}
                            loading={loadingOfferSList}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                          <h6
                            style={{
                              fontFamily: "Calibri",
                              color: "#1976d2",
                            }}
                          >
                            CARICAMENTO...
                          </h6>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              {offersListDownloaded !== null && (
                <>
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "10%",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        <Button
                          variant="contained"
                          component="label"
                          color="redtest"
                          startIcon={<ArrowBackIosNewOutlinedIcon />}
                          onClick={handleBackButton}
                        >
                          INDIETRO
                        </Button>
                      </div>
                      <div
                        style={{
                          width: "45%",
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                          padding: "0",
                        }}
                      >
                        <h1
                          style={{
                            color: "#1976d2",
                            fontSize: "3vw",
                            fontFamily: "Roboto, Arial, sans-serif",
                            textShadow: "2px 2px 8px rgba(0,0,0,0.2)",
                            letterSpacing: "0.1em",
                            margin: "0",
                          }}
                        >
                          {offersListDownloaded.anno}
                        </h1>
                      </div>
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                          textAlign: "left",
                          padding: "0",
                        }}
                      >
                        <h1
                          style={{
                            color: "#1976d2",
                            fontSize: "1.15vw",
                            fontFamily: "Roboto, Arial, sans-serif",
                            textShadow: "2px 2px 8px rgba(0,0,0,0.2)",
                            letterSpacing: "0.1em",
                            margin: "0",
                          }}
                        >
                          ULTIMA OFFERTA INSERITA:{" "}
                          {offersListDownloaded?.ultima_modifica !== null
                            ? visualizzaDataOra(
                                offersListDownloaded?.ultima_modifica
                              )
                            : "?"}{" "}
                          <br /> ULTIMO LISTINO CREATO:{" "}
                          {offersListDownloaded?.ultimo_listino !== null
                            ? visualizzaDataOra(
                                offersListDownloaded?.ultimo_listino
                              )
                            : "?"}
                        </h1>
                      </div>
                    </div>
                  </>
                  <>
                    {offerFormData === null && offerDataComplete === null && (
                      <>
                        <SingleOfferForm
                          updateOfferData={updateOfferData}
                          suppInsertedList={offersListSuppliers}
                        />
                        <div
                          style={{
                            textAlign: "center",
                            // marginTop: "1%",
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            color="bluetest"
                            startIcon={<SaveOutlinedIcon />}
                            onClick={() => setCreateOffersResumeOpen(true)}
                          >
                            SALVA LISTINO OFFERTE IN CORSO DI VALIDITA'
                          </Button>
                        </div>
                      </>
                    )}
                    {offerFormData !== null && offerDataComplete === null && (
                      <>
                        {!changeOfferFlag ? (
                          <>
                            <SingleOfferImport
                              updateDataFileChecked={updateDataFileChecked}
                              offerFormData={offerFormData}
                            />
                          </>
                        ) : (
                          <>
                            <SingleOfferImportChange
                              updateDataFileChecked={updateDataFileChecked}
                              offerFormData={offerFormData}
                              confirmChangeOFF={confirmChangeOFF}
                              setConfirmChangeOFF={setConfirmChangeOFF}
                              offersListDownloaded={offersListDownloaded}
                            />
                          </>
                        )}
                      </>
                    )}
                    {offerFormData !== null && offerDataComplete !== null && (
                      <>
                        <Box
                          sx={{
                            height: "400px",
                            width: "100%",
                            paddingLeft: "3%",
                            paddingRight: "3%",

                            "& .theme_header": {
                              backgroundColor: "#1976d2",
                              color: "#fff",
                              fontFamily: "Calibri",
                              fontSize: "16px",
                            },
                            "& .theme_cell": {
                              fontFamily: "Calibri",
                              fontSize: "17px",
                              border: "1px solid #000",
                            },
                          }}
                        >
                          <div
                            style={{
                              textAlign: "right",
                              display: "inline-block",
                              width: "100%",
                            }}
                          >
                            <h3
                              style={{
                                textAlign: "right",
                                fontFamily: "Calibri",
                                color: "#1976d2",
                              }}
                            >
                              TOT ELEMENTI:{" "}
                              {offerDataComplete.offerta_inserita.length}
                            </h3>
                          </div>
                          <DataGridPro
                            columns={SINGLE_OFF_MAIN_COLUMNS}
                            rows={offerDataComplete.offerta_inserita}
                            getRowId={(row) => row.id_offerta_temp}
                            headerHeight={40}
                            rowHeight={33}
                            disableColumnMenu
                            hideFooter
                          />
                        </Box>
                        <div
                          style={{
                            paddingTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "right",
                              display: "inline-block",
                              width: "75%",
                            }}
                          >
                            <EXP_OFF_MAIN_COMPLETE data={offerDataComplete} />
                          </div>
                          {!changeOfferFlag ? (
                            <div
                              style={{
                                textAlign: "center",
                                display: "inline-block",
                                width: "25%",
                              }}
                            >
                              <Button
                                variant="contained"
                                component="label"
                                color="success"
                                startIcon={<SaveOutlinedIcon />}
                                onClick={() => setSaveNewOfferOpen(true)}
                              >
                                SALVA OFFERTA
                              </Button>
                            </div>
                          ) : (
                            <div
                              style={{
                                textAlign: "center",
                                display: "inline-block",
                                width: "25%",
                              }}
                            >
                              <Button
                                variant="contained"
                                component="label"
                                color="success"
                                startIcon={<SaveOutlinedIcon />}
                                onClick={() => setChangeOfferOpen(true)}
                              >
                                SOSTITUISCI e/o INTEGRA OFFERTA
                              </Button>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                </>
              )}
            </>
          )}

          {yearSel !== null && (
            <OFF_CREATE_NEW_GROUP_POPUP
              saveNewGroupOpen={saveNewGroupOpen}
              setSaveNewGroupOpen={setSaveNewGroupOpen}
              handleCreateNewOfferList={handleCreateNewOfferList}
              yearSel={yearSel}
              loadingCreateListSave={loadingCreateListSave}
              salvaOffersGroup_ERR={salvaOffersGroup_ERR}
            />
          )}

          <OFFER_NEW_SAVE_POPUP
            saveNewOfferOpen={saveNewOfferOpen}
            // setSaveNewOfferOpen={setSaveNewOfferOpen}
            handleSaveSingleOfferPopupClose={handleSaveSingleOfferPopupClose}
            handleSaveSingleOffer={handleSaveSingleOffer}
            loadingSaveSingleOffer={loadingSaveSingleOffer}
            //---
            // pdf={pdf}
            // setPdf={}
            pdfUrl={offerFormData?.pdf_offerta_id}
            onFileUpload={onFileUpload}
            onFileChange={onFileChange}
            pdfFlagOK={pdfFlagOK}
            loadingPDF={loadingPDF}
          />

          <OFFER_CHANGE_SAVE_POPUP
            changeOfferOpen={changeOfferOpen}
            setChangeOfferOpen={setChangeOfferOpen}
            handleChangeSingleOffer={handleChangeSingleOffer}
            loadingChangeSingleOffer={loadingChangeSingleOffer}
            changeSaveError={changeSaveError}
            //---
            pdfUrl={offerFormData?.pdf_offerta_id}
            onFileUpload={onFileUpload}
            onFileChange={onFileChange}
            pdfFlagOK={pdfFlagOK}
            loadingPDF={loadingPDF}
          />

          <OFFER_CREATE_OFFERSRESUME_POPUP
            createOffersResumeOpen={createOffersResumeOpen}
            setCreateOffersResumeOpen={setCreateOffersResumeOpen}
            fileSavedName={fileSavedName}
            setFileSavedName={setFileSavedName}
            errors={errors}
            handleSaveOfferResume={handleSaveOfferResume}
            loadingSaveOffersResume={loadingSaveOffersResume}
            salvaOffersresume_ERR={salvaOffersresume_ERR}
            handleNotCreateResume={handleNotCreateResume}
          />
        </div>
      </ThemeProvider>
    </>
  );
};

export default OfferInsertMain;
