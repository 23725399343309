import acquistatoService from "../../services/acquistato";

const handleCreateStruttureList = (ACQ_DWN, setListaStrutture) => {
  const listaStrutture_DoppiRimossi = [
    "TUTTE LE STRUTTURE",
    ...new Set(ACQ_DWN.map((SEL) => SEL?.struttura)),
  ];

  const finalListaStruttureTEMP = listaStrutture_DoppiRimossi.map(
    (nome_struttura, index) => ({
      id: index,
      nome_struttura,
    })
  );

  setListaStrutture(finalListaStruttureTEMP);
};

const handleStruttureChange = (
  e,
  newValue,
  listaStrutture,
  setStruttureSelected
) => {
  const isTutteLeStruttureSelected = newValue.some((option) => option.id === 0);

  const updatedSelections = isTutteLeStruttureSelected
    ? listaStrutture.filter((option) => option.id !== 0)
    : newValue;

  setStruttureSelected(updatedSelections);
};

const groupBy = (array, key) => {
  return array.reduce((result, item) => {
    const groupKey = item[key];
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(item);
    return result;
  }, {});
};

const handlePurchasedDownload = async (
  ACQ_Selected,
  setLoadingSinglePurchased,
  setFlagACQDownloadError,
  setSingleACQ_Downloaded,
  setListaStrutture
) => {
  try {
    setLoadingSinglePurchased(true);
    setFlagACQDownloadError(false);

    const res = await acquistatoService.getAcquistatoByPurchasedId(
      ACQ_Selected.id
    );
    const acquistato = res.data.acquistato_inserito;

    // Ordina per principio attivo
    acquistato.sort((a, b) =>
      a.principio_attivo.localeCompare(b.principio_attivo)
    );

    // Raggruppa per principio attivo
    const groupedByPrincipioAttivo = groupBy(acquistato, "principio_attivo");

    // Elabora i dati raggruppati (se necessario, aggiungere altra logica qui)
    Object.values(groupedByPrincipioAttivo).forEach((group) => {
      // Ordina per codice gruppo ospedaliero
      group.sort((a, b) =>
        a.codice_gruppo_ospedaliero.localeCompare(b.codice_gruppo_ospedaliero)
      );

      // Raggruppa per codice gruppo ospedaliero
      const groupedByCodiceGruppo = groupBy(group, "codice_gruppo_ospedaliero");

      // Ordina i sottogruppi per prezzo unitario
      Object.values(groupedByCodiceGruppo).forEach((subGroup) => {
        subGroup.sort((a, b) => a.prezzo_unitario - b.prezzo_unitario);
      });
    });

    // Crea la lista delle strutture
    handleCreateStruttureList(acquistato, setListaStrutture);

    // Aggiorna lo stato con i dati scaricati
    setSingleACQ_Downloaded(res.data);

    setLoadingSinglePurchased(false);
  } catch (error) {
    console.error(
      "Errore durante il recupero dell'acquistato dal server:",
      error
    );
    setLoadingSinglePurchased(false);
    setFlagACQDownloadError(true);
  }
};

const handleResetACQDownload = (
  setSingleACQ_Downloaded,
  setACQ_Selected,
  setGareBenchmarking,
  setListaStrutture,
  setStruttureSelected,
  setArrayGrupedTEMP,
  setArrayGrupedFlag
) => {
  setSingleACQ_Downloaded(null);
  setACQ_Selected(null);
  setGareBenchmarking(null);
  setListaStrutture(null);
  setStruttureSelected(null);
  setArrayGrupedTEMP(null);
  setArrayGrupedFlag(false);
};

export {
  handleStruttureChange,
  handlePurchasedDownload,
  handleResetACQDownload,
};
