import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_DETTAGLIO_GP = (props) => {
  const { data, fileName } = props;

  // console.log("data", data);
  // console.log("fileName", fileName);

  const RapprPerc = (PercDaRappresentare) => {
    if (PercDaRappresentare === "") {
      return PercDaRappresentare;
    } else {
      let IntDec = PercDaRappresentare.toString();
      IntDec = IntDec.split(".");

      let nuovoVal = "";
      let vecchioVal = IntDec[0];

      while (vecchioVal?.length > 3) {
        nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
        vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
      }
      if (IntDec[1] !== undefined) {
        if (IntDec[1].length === 1) {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
        } else {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
        }
      } else {
        nuovoVal = vecchioVal + nuovoVal;
      }

      let finalVal = nuovoVal;

      if (finalVal.startsWith("-.")) {
        finalVal = "- " + finalVal.substr(2);
      } else if (finalVal.startsWith(".")) {
        finalVal = finalVal.substr(1);
      }

      return finalVal + "%";
    }
  };

  const createDownLoadData = () => {
    handleExport().then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", fileName + ".xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = async () => {
    let table1 = [
      {
        A: "DETTAGLIO",
        B: "N.",
        C: "ESITI GARA 2024",
        D: "%",
        E: "GARE PUBBLICHE",
        F: "DIFFERENZA",
        G: "DIFF. %",
      },
    ];

    let tableTEMP = [];

    data.forEach((row) => {
      tableTEMP.push({
        A: row?.title,
        B: row?.n_molecole,
        C: row?.spesaACQ,
        D: RapprPerc(row?.perc_spesaACQ),
        E: row?.spesaGarePub,
        F: row?.diff_spesa,
        G: RapprPerc(row?.perc_diff_spesa),
      });
    });

    table1 = table1.concat(tableTEMP);
    const wb = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "TABELLA BENCHMARKING");

    const workbookBlob = workbook2blob(wb);
    const dataInfo = {
      theadRange1: `A1:G1`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets("CON GARE PUB").forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "left",
          border: true,
        });

        sheet.column("A").width(35);
        sheet.column("B").width(10).style({ horizontalAlignment: "right" });
        sheet
          .column("C")
          .width(25)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
        sheet.column("D").width(15).style({ horizontalAlignment: "center" });
        sheet
          .column("E")
          .width(25)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
        sheet
          .column("F")
          .width(25)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
        sheet.column("G").width(15).style({ horizontalAlignment: "center" });

        if (dataInfo.theadRange1) {
          sheet.range(dataInfo.theadRange1).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }

        sheet.cell(2, 6).style({
          fill: "c6efce",
          fontColor: "006100",
          bold: false,
          border: true,
        });

        sheet.cell(2, 7).style({
          fill: "c6efce",
          fontColor: "006100",
          bold: false,
          border: true,
        });

        sheet.cell(3, 6).style({
          fill: "ffc7ce",
          fontColor: "9c0006",
          bold: false,
          border: true,
        });

        sheet.cell(3, 7).style({
          fill: "ffc7ce",
          fontColor: "9c0006",
          bold: false,
          border: true,
        });

        if (data[3].diff_spesa <= 0) {
          sheet.cell(5, 6).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: true,
            border: true,
          });
          sheet.cell(5, 7).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: true,
            border: true,
          });
        } else {
          sheet.cell(5, 6).style({
            fill: "ffc7ce",
            fontColor: "9c0006",
            bold: true,
            border: true,
          });
          sheet.cell(5, 7).style({
            fill: "ffc7ce",
            fontColor: "9c0006",
            bold: true,
            border: true,
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP TABELLA BENCHMARKING
      </Button>
    </>
  );
};

export default EXP_DETTAGLIO_GP;
